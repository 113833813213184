/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import {
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Rating,
  Skeleton,
  Stack,
} from "@mui/material";
import RatingModal from "pages/Home/Information/RatingModal";
import { useEffect, useState } from "react";

export const Item = ({ variant, color, children }) => {
  return (
    <MKTypography display="block" variant="p" lineHeight={1} sx={{ display: "flex" }}>
      {children}
    </MKTypography>
  );
};

function ActivityCard({
  variant,
  color,
  icon,
  idActivity,
  title,
  address,
  description,
  bies,
  date,
  rating,
  route,
}) {
  const navigate = useNavigate();
  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };

  const [ratingStars, setRatingStars] = useState(rating);

  let iconColor = color;

  if (variant === "gradient" && color !== "light") {
    iconColor = "white";
  } else if (variant === "gradient" && color === "light") {
    iconColor = "dark";
  }

  const handleOnClick = () => {
    navigate(route);
  };
  const ratingCallback = (stars) => {
    // console.group("RatingCallback");
    setRatingStars(stars);
    // console.log(stars);
    // console.groupEnd();
  };
  const checkShowRatingButton = () => {
    setRatingStars(rating);
  };
  useEffect(() => {
    checkShowRatingButton();
  }, [rating]);

  return (
    <Grid item md={6} xs={12}>
      <Card style={{ marginBottom: 10, borderRadius: 25 }}>
        <CardActionArea style={{ borderRadius: 25, padding: 20 }} onClick={handleOnClick}>
          {!title ? (
            <div style={{ padding: 20 }}>
              <Skeleton variant="h2" />
              <Skeleton variant="text" width={120} />
            </div>
          ) : (
            <CardHeader
              action={
                <IconButton aria-label="detail">
                  <MKTypography display="block" variant="h3">
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                  </MKTypography>
                </IconButton>
              }
              title={title}
              subheaderTypographyProps={{ fontSize: 12 }}
            />
          )}
          <Divider style={{ marginBottom: 10, marginTop: 0 }} />
          <MKBox pl={{ xs: 0, md: 2 }} lineHeight={1}>
            <Stack direction="column" spacing={2}>
              <Grid container>
                <Grid item xs="auto">
                  <Item>
                    <Icon>place</Icon>&nbsp;
                  </Item>
                </Grid>
                <Grid item xs>
                  {!address ? <Skeleton variant="body" /> : <Item>{address}</Item>}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs="auto">
                  <Item>
                    <Icon>home_repair_service</Icon>&nbsp;
                  </Item>
                </Grid>
                <Grid item xs>
                  {!description ? <Skeleton variant="body" /> : <Item>{description}</Item>}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs="auto">
                  <Item>
                    <Icon>person3</Icon>&nbsp;
                  </Item>
                </Grid>
                <Grid item xs>
                  {!bies ? (
                    <Skeleton variant="body" />
                  ) : (
                    <Item>{Array.isArray(bies) ? bies.join(", ") : "-"}</Item>
                  )}
                </Grid>
              </Grid>
              {ratingStars ? (
                <Grid container>
                  <Grid item xs>
                    <Item>
                      <Rating
                        name="half-rating"
                        precision={0.5}
                        value={ratingStars}
                        size="small"
                        readOnly
                      />
                    </Item>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Stack>
          </MKBox>
        </CardActionArea>
        {!ratingStars ? (
          <CardActions style={{ justifyContent: "center" }}>
            <RatingModal
              idActivity={idActivity}
              title={title ?? ""}
              description={description ?? ""}
              date={date ?? ""}
              bies={bies ?? []}
              callback={ratingCallback}
            />
          </CardActions>
        ) : (
          ""
        )}
      </Card>
    </Grid>
  );
}

// Setting default props for the ActivityCard
ActivityCard.defaultProps = {
  variant: "contained",
  color: "info",
  bies: [],
};

// Typechecking props for the ActivityCard
ActivityCard.propTypes = {
  variant: PropTypes.oneOf(["contained", "gradient"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  icon: PropTypes.node.isRequired,
  idActivity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  rating: PropTypes.string.isRequired,
  bies: PropTypes.array,
  route: PropTypes.string.isRequired,
};

export default ActivityCard;
