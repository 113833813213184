import React, { useState } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import moment from "moment";
import CalendarToday from "@mui/icons-material/CalendarToday";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AttendanceCard from "./AttendanceCard";

function AttendanceList(props) {
  const { data } = props;

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      {Object.keys(data).map((date) => {
        const momentDate = moment(date);
        const formattedDate = momentDate.format("D MMM YYYY");
        return (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h4">{formattedDate}</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              {data[date].map((activity) => {
                return (
                  <div
                    style={{ display: "flex", alignContent: "center", justifyContent: "center" }}
                  >
                    <AttendanceCard activity={activity} />
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default AttendanceList;
