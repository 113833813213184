import React, { useState, useEffect } from "react";
import { Box, Button, Card, Grid, Paper, Rating } from "@mui/material";
import ActivityCard from "pages/Activity/ActivityCard/ActivityCard";
import { latestActivityAsyncApi } from "services/ApiService";
import MKTypography from "components/MKTypography";
import RatingModal from "./RatingModal";

function Section() {
  const [latestActivity, setLatestActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let subscribed = true;
    setIsLoading(true);
    latestActivityAsyncApi()
      .then((res) => {
        if (subscribed) {
          sessionStorage.setItem("latestActivity", JSON.stringify(res.data.data));
          setLatestActivity(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });

    return () => {
      subscribed = true;
    };
  }, []);

  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <ImageButton
            title="My AC List"
            description="Click Here to see my AC list"
            route="/unit"
            imageUrl="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <ImageButton
            title="My Activity List"
            description="Click Here to see all of activity list"
            route="/activity"
            imageUrl="https://images.unsplash.com/photo-1544717302-de2939b7ef71?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <DefaultInfoCard
            icon="public"
            title="Fully integrated"
            description="We get insulted by others, lose trust for those We get back freezes"
          />
        </Grid>
      </Grid> */}
      <ActivityCard
        variant="gradient"
        color="info"
        icon="flag"
        idActivity={latestActivity.id}
        title={latestActivity.end_at}
        address={latestActivity.address}
        description={latestActivity.description}
        bies={latestActivity.activity_bies}
        date={latestActivity.end_at}
        rating={latestActivity.activity_rating}
        route={`/activityDetail/${latestActivity.id}`}
      />
    </>
  );
}

export default Section;
