import React from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

function Photoswipe({ data, url }) {
  const smallItemStyles = {
    cursor: "pointer",
    objectFit: "cover",
    width: "100%",
    maxHeight: "100%",
  };

  const uiElements = [
    {
      name: "bulletsIndicator",
      order: 9,
      isButton: false,
      appendTo: "wrapper",
      onInit: (tempEl, pswpInstance) => {
        let prevIndex = -1;
        const thumbnails = [];
        const el = tempEl;

        el.style.position = "absolute";
        el.style.bottom = "20px";
        el.style.left = "10px";
        el.style.right = "0";
        el.style.display = "grid";
        el.style.gridGap = "10px";
        el.style.gridTemplateColumns = "repeat(auto-fit, 40px)";
        el.style.gridTemplateRows = "repeat(auto-fit, 40px)";
        el.style.justifyContent = "center";

        const { dataSource } = pswpInstance.options;

        for (let i = 0; i < dataSource.length; i += 1) {
          const slideData = dataSource[i];

          const thumbnail = document.createElement("div");
          thumbnail.style.transition = "transform 0.15s ease-in";
          thumbnail.style.opacity = "0.6";
          thumbnail.style.cursor = "pointer";
          thumbnail.onclick = (e) => {
            const { target } = e;
            const thumbnailEl = target.tagName === "IMG" ? target.parentElement : e.target;
            pswpInstance.goTo(thumbnails.indexOf(thumbnailEl));
          };

          const thumbnailImage = document.createElement("img");
          thumbnailImage.setAttribute("src", slideData.msrc);
          thumbnailImage.style.width = "100%";
          thumbnailImage.style.height = "100%";
          thumbnailImage.style.objectFit = "cover";

          thumbnail.appendChild(thumbnailImage);

          el.appendChild(thumbnail);

          thumbnails.push(thumbnail);
        }

        pswpInstance.on("change", () => {
          if (prevIndex >= 0) {
            const prevThumbnail = thumbnails[prevIndex];
            prevThumbnail.style.opacity = "0.6";
            prevThumbnail.style.cursor = "pointer";
            prevThumbnail.style.transform = "scale(1)";
          }

          const currentThumbnail = thumbnails[pswpInstance.currIndex];
          currentThumbnail.style.opacity = "1";
          currentThumbnail.style.cursor = "unset";
          currentThumbnail.style.transform = "scale(1.2)";

          prevIndex = pswpInstance.currIndex;
        });
      },
    },
  ];
  return (
    <Gallery uiElements={uiElements}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
          gridGap: 12,
        }}
      >
        {Array.isArray(data) &&
          data.map((item) => (
            <Item
              original={`${url}/${item.path}`}
              thumbnail={`${url}/${item.path}`}
              width="1600"
              height="1600"
              alt={item.id_activity ?? ""}
            >
              {({ ref, open }) => (
                <img
                  alt={item.id_activity ?? ""}
                  style={smallItemStyles}
                  src={`${url}/${item.path}`}
                  ref={ref}
                  onClick={open}
                />
              )}
            </Item>
          ))}
      </div>
    </Gallery>
  );
}

Photoswipe.propTypes = {};

export default Photoswipe;
