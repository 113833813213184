/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import moment from "moment";

function AttendanceCard(props) {
  const { activity } = props;
  const timeString = activity.time_array;
  const timeArray = timeString.split(";");
  const startAt = timeArray[0] ? moment(timeArray[0], "HH:mm:ss").format("HH:mm") : "Not Timed Yet";
  const endAt = timeArray[1] ? moment(timeArray[1], "HH:mm:ss").format("HH:mm") : "Not Timed Yet";

  const photoArray = activity.photo.split(";");
  const photoStart = photoArray[0];
  const photoEnd = photoArray[1];

  const abbreviate = (name) => {
    const words = name.split(" ");
    // If the name has more than two words
    if (words.length > 2) {
      const firstTwoWords = words.slice(0, 2).join(" ");
      const rest = words
        .slice(2)
        .map((word) => word[0])
        .join(".");
      return `${firstTwoWords} ${rest}`;
    }
    // If the name has two or fewer words
    return name;
  };

  return (
    <>
      <Card sx={{ width: 200, margin: 1 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {abbreviate(activity.username)}
          </Typography>
          <Typography>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
              style={{ margin: 0, padding: 0 }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <p style={{ fontSize: 12 }}>{startAt}</p>
                  {photoStart && (
                    <img
                      alt="Time in"
                      src={photoStart}
                      style={{ width: 100, height: "auto", margin: 2, borderRadius: 10 }}
                      loading="lazy"
                    />
                  )}
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot />
                </TimelineSeparator>
                <TimelineContent>
                  <p style={{ fontSize: 12 }}>{endAt}</p>
                  {photoEnd && (
                    <img
                      alt="Time out"
                      src={photoEnd}
                      style={{ width: 100, height: "auto", margin: 2, borderRadius: 10 }}
                      loading="lazy"
                    />
                  )}
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Share</Button>
          <Button size="small">Learn More</Button>
        </CardActions> */}
      </Card>
    </>
  );
}

export default AttendanceCard;
