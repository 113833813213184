/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, useLocation, defer } from "react-router-dom";

// Material Kit 2 React themes
import Presentation from "layouts/pages/presentation";
import SignIn from "layouts/pages/authentication/sign-in";

// Material Kit 2 React routes
import routes from "routes/routes";
import otherRoutes from "routes/otherRoutes";
import Home from "pages/Home/Home";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthLayout } from "./layouts/AuthLayout";
import { ProtectedLayout } from "./layouts/ProtectedLayout";

export default function App() {
  const { pathname } = useLocation();

  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true"
  );

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const getUserData = () =>
    new Promise((resolve) =>
      setTimeout(() => {
        const user = window.localStorage.getItem("user");
        resolve(user);
      }, 3000)
    );

  return (
    <>
      <Routes element={<AuthLayout />} loader={() => defer({ userPromise: getUserData() })}>
        <Route index path="/login" element={<SignIn />} />
        <Route element={<ProtectedLayout isAllowed={authenticated} />}>
          {getRoutes(routes)}
          {getRoutes(otherRoutes)}
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
}
