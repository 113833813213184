import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";
import BaseLayout from "layouts/sections/components/BaseLayout";
import AttendanceList from "./AttendanceList";

function Attendance() {
  const activityStructure = {
    code: "",
    content: {},
    customer_product_histories: {},
    id: "",
  };
  const [attendance, setAttendance] = useState(
    JSON.parse(sessionStorage.getItem("attendance")) ?? []
  );
  const [isLoading, setIsLoading] = useState(false);

  const groupBy = (data, key) => {
    return data.reduce((acc, item) => {
      const { date } = item;
      if (!acc[date]) {
        acc[date] = [item];
      } else {
        acc[date].push(item);
      }
      return acc;
    }, {});
  };

  useEffect(() => {
    let subscribed = true;
    setIsLoading(true);
    axios
      // .get("http://192.168.1.23:8000/api/attendance/getAttendance")
      .get("http://masterteam.maspindo.com/api/attendance/getAttendance")
      .then((res) => {
        if (subscribed) {
          const grouped = groupBy(res.data, "date");
          setAttendance(grouped);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });

    return () => {
      subscribed = true;
    };
  }, []);

  return (
    <LoadingSpinnerOverlay isActive={isLoading}>
      <BaseLayout title="Attendance" breadcrumb={[{ label: "Attendance" }]}>
        <AttendanceList data={attendance} />
      </BaseLayout>
    </LoadingSpinnerOverlay>
  );
}

export default Attendance;
