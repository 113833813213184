// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import bgImage2 from "assets/images/chiller2.png";

import Section from "./Section";

function Information() {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container style={{ display: "flex" }}>
        <Grid container item xs={12} lg={12} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title="Lastest Activity"
                description="My Lastest Activity Detail"
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More Activity"
                description="You will find more activity histories here"
                action={{
                  type: "internal",
                  route: "/activity",
                  label: "Activity List",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} md={8} lg={8} sx={{ mx: "auto" }}>
            <Section />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
