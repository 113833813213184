import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { BaseAPIURL } from "services/AuthService";

import PropTypes from "prop-types";

const ActivityDetailDownload = ({ summaryPdf, reportPdf }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadFile = (filePath) => {
    setTimeout(() => {
      const link = document.createElement("a");
      link.download = "testDownload.pdf";
      link.href = filePath;
      link.target = "_blank";
      link.click();
    }, 500);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        marginTop: 2,
        marginBottom: 3,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        orientation="vertical"
      >
        {summaryPdf ? (
          <Tab label="Summary Report" onClick={() => downloadFile(`${BaseAPIURL}/${summaryPdf}`)} />
        ) : (
          <Tab label="No Summary Report" disabled />
        )}
        {Array.isArray(reportPdf)
          ? reportPdf.map((pdf, index) => {
              return (
                <Tab
                  key={pdf.id}
                  label={pdf.report_code ? pdf.report_code : `Report ${index + 1}`}
                  onClick={() => downloadFile(`${BaseAPIURL}/${pdf.path}`)}
                />
              );
            })
          : ""}
      </Tabs>
    </Box>
  );
};

ActivityDetailDownload.defaultProps = {
  summaryPdf: "",
  reportPdf: [
    {
      id: "",
      report_code: "",
      path: "",
    },
  ],
};

ActivityDetailDownload.propTypes = {
  summaryPdf: PropTypes.string,
  reportPdf: PropTypes.array,
};

export default ActivityDetailDownload;
