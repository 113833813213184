import React, { useEffect, useState } from "react";
import BaseLayout from "layouts/sections/components/BaseLayout";
import {
  Box,
  Grid,
  TextField,
  Icon,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";
import Pagination from "@mui/material/Pagination";
import usePagination from "hooks/pagination";
import defaultImage from "assets/images/image-not-available.png";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { unitIndexAsyncApi } from "../../services/ApiService";
import CSTCenteredCard from "./CSTCenteredCard/CSTCenteredCard";

function Unit() {
  const navigate = useNavigate();
  const unitStructure = {
    id: "",
    filter: [],
    code: "",
    content: {},
    customer_product_histories: {},
  };
  const [units, setUnits] = useState(
    JSON.parse(sessionStorage.getItem("units")) ?? [unitStructure]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [filters, updateFilters] = useState(units);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const count = Math.ceil(filters.length / perPage);
  const pageRows = usePagination(filters, perPage);

  const handleChange = (e, p) => {
    setPage(p);
    pageRows.jump(p);
  };
  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  // Filter change handler
  const onFilter = (event) => {
    const {
      target: { value },
    } = event;

    updateFilters(() => {
      const temp = units
        .filter(({ filter }) => filter.toString().toLowerCase().indexOf(value.toLowerCase()) > -1)
        .map((f) => {
          return { ...f };
        });
      return temp;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    unitIndexAsyncApi()
      .then((res) => {
        setIsLoading(false);
        setUnits(res.data.data);
        updateFilters(res.data.data);
        sessionStorage.setItem("units", JSON.stringify(res.data.data));
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, []);

  return (
    <LoadingSpinnerOverlay isActive={isLoading}>
      <BaseLayout
        title="AC Unit"
        breadcrumb={[
          // { label: "Page Sections", route: "/sections/navigation/navbars" },
          { label: "Unit" },
        ]}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 2 }}
        >
          <Grid container spacing={10}>
            <Grid item xs={8}>
              <TextField
                id="search-outline"
                label="Search"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={onFilter}
                fullWidth="true"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="standard" fullWidth="true">
                <InputLabel id="demo-simple-select-label">Page Limit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={perPage}
                  label="Age"
                  onChange={handleChangePerPage}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          my={2}
        />
        <Grid container spacing={2} my={2}>
          {pageRows.currentData().map((unit) => {
            return unit.id ? (
              <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
                <CSTCenteredCard
                  image={unit.image ?? defaultImage}
                  title={unit.code ?? "-"}
                  position={{ color: "info", labels: unit.content }}
                  description={`Total Service Times: ${unit.countHistory}`}
                  action={{
                    type: "internal",
                    route: `/unitHistory/${unit.id}`,
                    color: "info",
                    label: "find out more",
                  }}
                  onClickCallback={() => navigate(`/unitHistory/${unit.id}`)}
                />
              </Grid>
            ) : (
              <MKBox
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  padding: 10,
                  alignItems: "center",
                }}
              >
                <MKTypography variant="body">No Unit Available ...</MKTypography>
              </MKBox>
            );
          })}
        </Grid>

        <Pagination
          count={count}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </BaseLayout>
    </LoadingSpinnerOverlay>
  );
}

export default Unit;
