/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { CardActionArea, Grid } from "@mui/material";
import MKBadge from "components/MKBadge";

function CSTCenteredCard({ image, title, position, description, action, onClickCallback, style }) {
  return (
    <Card sx={{ my: 2 }} style={({ borderRadius: 10 }, style)}>
      <CardActionArea onClick={onClickCallback} style={{ borderRadius: 10 }}>
        <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
          <MKBox
            component="img"
            src={image}
            alt={title}
            borderRadius="lg"
            width="100%"
            position="relative"
            zIndex={1}
          />
          <MKBox
            borderRadius="lg"
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top={0}
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </MKBox>
        <MKBox p={1} my={1} textAlign="center" style={{ justifyContent: "center", width: "100%" }}>
          <MKTypography
            display="inline"
            variant="h5"
            textTransform="capitalize"
            fontWeight="regular"
          >
            {title}
          </MKTypography>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={1}
            mb={3}
          >
            {position
              ? Object.keys(position.labels).length !== 0 &&
                Object.keys(position.labels).map((key, index) => (
                  <Grid item xs={12} key={index} p={0} m={0}>
                    <MKBadge
                      badgeContent={`${key} - ${position.labels[key]}`}
                      variant="contained"
                      color={position.color}
                    />
                  </Grid>
                ))
              : ""}
          </Grid>
          <MKBox mt={1} mb={3}>
            <MKTypography variant="body2" component="p" color="text">
              {description}
            </MKTypography>
          </MKBox>
          {action.type === "external" ? (
            <MKButton
              component={MuiLink}
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="gradient"
              size="small"
              color={action.color ? action.color : "dark"}
            >
              {action.label}
            </MKButton>
          ) : (
            <MKButton
              component={Link}
              to={action.route}
              variant="gradient"
              size="small"
              color={action.color ? action.color : "dark"}
            >
              {action.label}
            </MKButton>
          )}
        </MKBox>
      </CardActionArea>
    </Card>
  );
}

// Typechecking props for the CSTCenteredCard
CSTCenteredCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    labels: PropTypes.shape({
      label: PropTypes.shape({}),
    }),
  }).isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
};

export default CSTCenteredCard;
