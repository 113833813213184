import axios from "axios";
import { authHeader, ServerAPIURl, handleError } from "./AuthService";

// export const activityHistoryIndexAsyncApi = async () => {
//   try {
//     const response = await axios.get(`${ServerAPIURl}/activityHistory`, {
//       headers: authHeader(),
//     });
//     return response;
//   } catch (error) {
//     handleError(error);
//     throw error;
//   }
// };
export const unitIndexAsyncApi = async () => {
  try {
    const response = await axios.get(`${ServerAPIURl}/activityHistory`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
export const unitHistoryAsyncApi = async (id) => {
  try {
    const response = await axios.get(`${ServerAPIURl}/unitHistory/${id}`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
export const activityIndexAsyncApi = async (id) => {
  try {
    const response = await axios.get(`${ServerAPIURl}/activityIndex`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
export const activityDetailAsyncApi = async (id) => {
  try {
    const response = await axios.get(`${ServerAPIURl}/activityDetail/${id}`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
export const latestActivityAsyncApi = async () => {
  try {
    const response = await axios.get(`${ServerAPIURl}/latestActivity`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

export const counterDashboardAsyncApi = async () => {
  try {
    const response = await axios.get(`${ServerAPIURl}/dashboardCounter`, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
export const submitRatingAsyncApi = async (payload) => {
  try {
    const response = await axios.post(`${ServerAPIURl}/submitRating`, payload, {
      headers: authHeader(),
    });
    return response;
  } catch (error) {
    handleError(error);
    throw error;
  }
};
