import React from "react";
import LoadingOverlay from "react-loading-overlay";
import HashLoader from "react-spinners/HashLoader";

const LoadingSpinnerOverlay = ({ isActive, children }) => {
  return (
    <LoadingOverlay active={isActive} spinner={<HashLoader />}>
      {children}
    </LoadingOverlay>
  );
};

export default LoadingSpinnerOverlay;
