import React, { useEffect, useState } from "react";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { activityIndexAsyncApi } from "services/ApiService";
import ActivityPagination from "./ActivityPagination/ActivityPagination";

function Activity() {
  const activityStructure = {
    code: "",
    content: {},
    customer_product_histories: {},
    id: "",
  };
  const [activities, setActivities] = useState(
    JSON.parse(sessionStorage.getItem("activities")) ?? [activityStructure]
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let subscribed = true;
    setIsLoading(true);
    activityIndexAsyncApi()
      .then((res) => {
        if (subscribed) {
          sessionStorage.setItem("activities", JSON.stringify(res.data.data));
          setActivities(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });

    return () => {
      subscribed = true;
    };
  }, []);
  return (
    <LoadingSpinnerOverlay isActive={isLoading}>
      <BaseLayout
        title="Activity"
        breadcrumb={[
          // { label: "Page Sections", route: "/sections/navigation/navbars" },
          { label: "Activity" },
        ]}
      >
        <ActivityPagination data={activities} />
      </BaseLayout>
    </LoadingSpinnerOverlay>
  );
}

export default Activity;
