import { Card, Divider } from "@mui/material";
import MKTypography from "components/MKTypography";
import Photoswipe from "components/Photoswipe/Photoswipe";
import React from "react";

const Gallery = ({ title, data, url }) => {
  return (
    <Card variant="outlined" style={{ padding: 20, marginBottom: 20 }}>
      {title ? (
        <>
          <b>{title}</b>
          <Divider />
        </>
      ) : (
        ""
      )}

      {data.length > 0 ? <Photoswipe data={data} url={url} /> : <p>No Image Available</p>}
    </Card>
  );
};

export default Gallery;
