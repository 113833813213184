import Icon from "@mui/material/Icon";
import ActivityDetail from "pages/Activity/ActivityDetail";
import UnitHistory from "pages/Unit/UnitHistory";

const otherRoutes = [
  {
    name: "Unit History",
    route: "/unitHistory/:unitId",
    icon: <Icon>inventory</Icon>,
    dropdown: false,
    component: <UnitHistory />,
  },
  {
    name: "Activity Detail",
    route: "/activityDetail/:activityId",
    icon: <Icon>list</Icon>,
    dropdown: false,
    component: <ActivityDetail />,
  },
];

export default otherRoutes;
