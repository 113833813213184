import {
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";
import MKTypography from "components/MKTypography";
import BaseLayout from "layouts/sections/components/BaseLayout";
import Gallery from "pages/Unit/HistoryCard/Gallery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { activityDetailAsyncApi } from "services/ApiService";
import { BaseAPIURL } from "services/AuthService";
import ActivityDetailDownload from "./ActivityDetailDownload/ActivityDetailDownload";

const ActivityDetail = () => {
  const { activityId } = useParams();
  const [activity, setActivity] = useState({});
  const [reportPdfs, setReportPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ratings = {
    0.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    1: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    1.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    2: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    2.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    3: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    3.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    4: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    4.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
    ],
    5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star
      </Icon>,
    ],
  };
  useEffect(() => {
    let subscribed = true;
    setIsLoading(true);
    activityDetailAsyncApi(activityId)
      .then((res) => {
        if (subscribed) {
          setActivity(res.data.data);
          if (res.data.data.reports.length > 0) {
            setReportPdfs(res.data.data.reports);
          }
          if (res.data.data.customerProductHistories.length > 0) {
            setReportPdfs(res.data.data.customerProductHistories);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });

    return () => {
      subscribed = true;
    };
  }, []);

  return (
    <LoadingSpinnerOverlay isActive={isLoading}>
      <BaseLayout
        title="Detail"
        breadcrumb={[{ label: "Activity", route: "/activity" }, { label: "Detail" }]}
      >
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Activity Code</b>
              </TableCell>
              <TableCell>{activity.activity_code ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Date</b>
              </TableCell>
              <TableCell>{activity.end_at ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Address</b>
              </TableCell>
              <TableCell>{activity.address ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Description</b>
              </TableCell>
              <TableCell>{activity.description ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Done By</b>
              </TableCell>
              <TableCell>
                {Array.isArray(activity.activities_bies)
                  ? activity.activities_bies.join(", ")
                  : "N/A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Signed By</b>
              </TableCell>
              <TableCell>{activity.customer_name ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Rating</b>
              </TableCell>
              <TableCell>
                <MKTypography
                  variant="h4"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: 0.375,

                    "& .material-icons-round": {
                      ml: -0.375,
                    },
                  }}
                >
                  {ratings[activity.activity_rating]}
                </MKTypography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Comments</b>
              </TableCell>
              <TableCell>{activity.activity_comment ?? "N/A"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>View Report Below :</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ActivityDetailDownload summaryPdf={activity.activity_pdf} reportPdf={reportPdfs} />

        {activity.photo_before && activity.photo_after.length !== 0 ? (
          <Gallery title="Before" data={activity.photo_before} url={BaseAPIURL} />
        ) : (
          ""
        )}
        {activity.photo_after && activity.photo_after.length !== 0 ? (
          <Gallery title="After" data={activity.photo_after} url={BaseAPIURL} />
        ) : (
          ""
        )}
      </BaseLayout>
    </LoadingSpinnerOverlay>
  );
};

export default ActivityDetail;
