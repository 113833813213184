import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import usePagination from "hooks/pagination";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
} from "@mui/material";
import { Tag } from "@mui/icons-material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import ActivityCard from "../ActivityCard/ActivityCard";

export default function ActivityPagination({ data }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const count = Math.ceil(data.length / perPage);
  const pageRows = usePagination(data, perPage);

  const handleChange = (e, p) => {
    setPage(p);
    pageRows.jump(p);
  };
  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  return (
    <Box p="5">
      <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
        <FormControl variant="standard" style={{ width: "30%" }}>
          <InputLabel id="demo-simple-select-label">Page Limit</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={perPage}
            label="Age"
            onChange={handleChangePerPage}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Pagination
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
        my={2}
      />

      <Grid container spacing={2} my={2}>
        {pageRows.currentData().map((row) => {
          return row.id ? (
            <ActivityCard
              variant="gradient"
              color="info"
              icon="flag"
              idActivity={row.id}
              title={row.end_at}
              address={row.address}
              description={row.description}
              bies={row.activity_bies}
              date={row.end_at}
              rating={row.activity_rating}
              route={`/activityDetail/${row.id}`}
            />
          ) : (
            <MKBox
              sx={{
                justifyContent: "center",
                display: "flex",
                padding: 10,
                alignItems: "center",
              }}
            >
              <MKTypography variant="body">No Activity Available ...</MKTypography>
            </MKBox>
          );
        })}
      </Grid>

      <Pagination
        count={count}
        size="large"
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handleChange}
      />
    </Box>
  );
}
