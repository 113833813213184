import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { counterDashboardAsyncApi } from "services/ApiService";
import { useNavigate } from "react-router-dom";
import MKButton from "components/MKButton";

function Counters() {
  const navigate = useNavigate();
  const [totalActivity, setTotalActivity] = useState(0);
  const [totalUnit, setTotalUnit] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let subscribed = true;
    setIsLoading(true);
    counterDashboardAsyncApi()
      .then((res) => {
        if (subscribed) {
          setTotalActivity(res.data.total_activity);
          setTotalUnit(res.data.total_unit);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });

    return () => {
      subscribed = true;
    };
  }, []);

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={6} justifyContent="center" display="flex" direction="column">
            <DefaultCounterCard
              count={totalActivity}
              title="Total Activities"
              description="How many times you serviced"
            />
            <MKBox sx={{ justifyContent: "center", display: "flex" }}>
              <MKButton variant="contained" color="info" onClick={() => navigate("activity")}>
                See Detail
              </MKButton>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} justifyContent="center" display="flex" direction="column">
            <DefaultCounterCard
              count={totalUnit}
              title="Total Units"
              description="How many units of AC"
            />
            <MKBox sx={{ justifyContent: "center", display: "flex" }}>
              <MKButton variant="contained" color="info" onClick={() => navigate("unit")}>
                See Detail
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
