import axios from "axios";
import { toast } from "react-toastify";

export const BaseAPIURL = "https://masterreport.mtpindo.co.id";
// export const BaseAPIURL = "https://masterreport.test";
export const ServerAPIURl = `${BaseAPIURL}/api/client`;

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = localStorage.getItem("token");
  if (currentUser) {
    return {
      Authorization: `Bearer ${currentUser}`,
    };
  } else {
    return {};
  }
}

export const handleError = (error) => {
  if (error.response) {
    let message = "";
    if (error.response.status === 401) {
      message =
        "Access Restricted: Your account does not have the necessary permissions. Please contact the administrator for assistance.";
      localStorage.removeItem("token");
      localStorage.removeItem("profileURL");
      localStorage.removeItem("authenticated");
      localStorage.removeItem("username");
      localStorage.removeItem("company");
    } else if (error.response.status === 404) {
      message =
        "Oops! The page you're looking for can't be found. Please check the URL or go back to the homepage.";
    } else if (error.response.status === 405) {
      message =
        "Action Not Allowed: The method you are using is not supported for this resource. Please try a different action.";
    } else if (error.response.status === 422) {
      message =
        "Validation Error: The data you submitted could not be processed. Please review your input and try again.";
      // Validation Message
    } else if (error.response.status >= 500) {
      message =
        "Something went wrong on our end. Please try again later or contact support if the issue persists.";
    }

    // Try to Use the Response Message
    if (
      Object.prototype.hasOwnProperty.call(error, "response") &&
      Object.prototype.hasOwnProperty.call(error, "data")
    ) {
      if (
        Object.prototype.hasOwnProperty.call(error, "message") &&
        error.response.data.message.length > 0
      ) {
        message = error.response.data.message;
        if (Object.prototype.hasOwnProperty.call(error, "errors") && error.response.data.errors) {
          Object.keys(error.response.data.errors).forEach((key) => {
            message = message.concat(error.response.data.errors[key]);
          });
        }
      }
    }
    toast.error(`${message}`, {});
  } else if (error.request) {
    toast.error(`${error.request}`, {});
  } else {
    toast.error(`${error.message}`, {});
  }
};

export const login = (email, password) => {
  axios
    .post(`${ServerAPIURl}/login`, {
      email,
      password,
    })
    .then((response) => {
      if (response.data.status === "success") {
        const isAuthenticated = true;
        localStorage.setItem("authenticated", isAuthenticated);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("company", response.data.company);
        localStorage.setItem("username", response.data.username);
        window.location.href = "/";
      } else {
        toast.error(`Wrong Email or Password`, {});
      }
    })
    .catch((error) => {
      handleError(error);
      // window.location.href = "/login";
    });
};

export const logout = () => {
  localStorage.removeItem("authenticated");
  localStorage.clear();
  window.location.href = "/";
};
