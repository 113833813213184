import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Divider, Icon, Rating, Slide, TextField } from "@mui/material";
import rating from "assets/images/json/rating.json";
import Lottie from "lottie-react";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { submitRatingAsyncApi } from "services/ApiService";
import { toast } from "react-toastify";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

const labels = {
  0.5: "Worst",
  1: "Worst",
  1.5: "Bad",
  2: "Bad",
  2.5: "Ok",
  3: "OK",
  3.5: "Good",
  4: "Good",
  4.5: "Good",
  5: "Excellent",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}
// First Modal
const FirstModal = (props) => {
  const { onClose, open, starValue, title, description, date, bies } = props;
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onClose(starValue);
  };
  const handleChangeStar = (event, value) => {
    onClose(value);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={handleClose}
      open={open}
      style={{ padding: 20 }}
    >
      <DialogTitle align="center">Please Rate Our Service</DialogTitle>
      <List sx={{ pt: 0, marginLeft: 5, marginRight: 5 }}>
        <ListItem disableGutters style={{ justifyContent: "center" }}>
          <MKTypography>{title}</MKTypography>
        </ListItem>
        <Divider />
        <ListItem disableGutters style={{ justifyContent: "flex-start" }}>
          <MKTypography>
            <Icon>home_repair_service</Icon>&nbsp;{description}
          </MKTypography>
        </ListItem>
        <ListItem disableGutters style={{ justifyContent: "flex-start" }}>
          <MKTypography>
            <Icon>calendar_month</Icon>&nbsp;{date}
          </MKTypography>
        </ListItem>
        <ListItem disableGutters style={{ justifyContent: "flex-start" }}>
          <MKTypography>
            <Icon>person3</Icon>&nbsp;{Array.isArray(bies) ? bies.join(", ") : "-"}
          </MKTypography>
        </ListItem>
        <ListItem
          disableGutters
          style={{ justifyContent: "center", paddingTop: 20, paddingBottom: 50 }}
        >
          <Rating
            name="half-rating"
            defaultValue={0}
            precision={0.5}
            size="large"
            style={{ fontSize: "3em" }}
            value={starValue}
            onChange={handleChangeStar}
          />
        </ListItem>
      </List>
    </Dialog>
  );
};

// Second Modal
const SecondModal = (props) => {
  const { onClose, open, idActivity, commentValue, starValue, callback } = props;
  const [comment, setComments] = useState(commentValue);

  const handleChangeComment = (event) => {
    setComments(event.target.value);
  };
  const handleSubmit = (event) => {
    const payload = {
      id_activity: idActivity,
      stars: starValue,
      comments: comment,
    };
    submitRatingAsyncApi(payload)
      .then((res) => {
        toast.success(res.data.message, {});
        callback(starValue);
      })
      .catch((err) => {
        throw err;
      });
    onClose();
  };

  return (
    <Dialog TransitionComponent={Transition} scroll="body" onClose={() => {}} open={open}>
      <DialogTitle align="center">Submit Rating</DialogTitle>
      <List>
        <ListItem disableGutters style={{ justifyContent: "center", mb: "10" }}>
          <Rating
            name="half-rating"
            defaultValue={0}
            precision={0.5}
            getLabelText={getLabelText}
            size="large"
            style={{ fontSize: "2em" }}
            value={starValue}
            readOnly
          />
        </ListItem>
        <ListItem disableGutters style={{ justifyContent: "center", mb: "10" }}>
          <MKTypography sx={{ ml: 2 }}>{labels[starValue]}</MKTypography>
        </ListItem>
        <ListItem disableGutters style={{ justifyContent: "center", mb: "10" }}>
          <Lottie animationData={rating} style={{ width: "50%" }} />
        </ListItem>
        <ListItem disableGutters style={{ justifyContent: "center" }}>
          <TextField
            id="outlined-textarea"
            label="Comments"
            placeholder="Fill your comments here..."
            fullWidth
            multiline
            rows={3}
            value={comment}
            onChange={handleChangeComment}
            style={{ width: "80%", margin: 20 }}
            autoFocus="true"
          />
        </ListItem>
        <ListItem disableGutters style={{ justifyContent: "center" }}>
          <MKButton
            variant="gradient"
            color="info"
            fullWidth
            style={{ width: "80%", marginBottom: 30, alignItems: "center" }}
            onClick={handleSubmit}
          >
            Submit <Icon>send</Icon>
          </MKButton>
        </ListItem>
      </List>
    </Dialog>
  );
};

FirstModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  starValue: PropTypes.number.isRequired,
  title: PropTypes.number.isRequired,
  description: PropTypes.number.isRequired,
  bies: PropTypes.number.isRequired,
};
SecondModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  starValue: PropTypes.number.isRequired,
  commentValue: PropTypes.string.isRequired,
};

// Final Export
const RatingModal = ({ idActivity, title, description, date, bies, callback }) => {
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const [openSecondModal, setOpenSecondModal] = useState(false);
  const [starValue, setStarValue] = useState(0);
  const [commentValue, setCommentValue] = useState("");

  const handleClickOpenRating = () => {
    setOpenFirstModal(true);
  };

  const handleCloseFirstModal = (value) => {
    setOpenFirstModal(false);
    setStarValue(value);
    setOpenSecondModal(true);
  };

  const handleCloseSecondModal = (value) => {
    setOpenSecondModal(false);
  };

  return (
    <>
      <MKButton variant="contained" color="info" onClick={handleClickOpenRating}>
        Rate This Service
      </MKButton>
      <FirstModal
        open={openFirstModal}
        onClose={handleCloseFirstModal}
        title={title}
        description={description}
        bies={bies}
        date={date}
        starValue={starValue}
      />
      <SecondModal
        idActivity={idActivity}
        starValue={starValue}
        commentValue={commentValue}
        open={openSecondModal}
        onClose={handleCloseSecondModal}
        callback={callback}
      />
    </>
  );
};
export default RatingModal;
