/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes/routes";

// Images
import bgImage from "assets/images/chiller.png";
import bgImage2 from "assets/images/chiller2.png";
import logo from "assets/images/logo-horizontal.png";

import Counters from "./Counters";
import Information from "./Information/Information";
import Features from "./Features";
import BigCard from "./BigCard";

const Home = () => {
  const date = new Date().getFullYear();
  const companyName = localStorage.getItem("company");
  const username = localStorage.getItem("username");
  return (
    <>
      <DefaultNavbar
        routes={routes}
        brand="MasterReport"
        action={{
          type: "external",
          route: "https://mastercool.id",
          label: "Our Website",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            {/* <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              MasterCool
            </MKTypography> */}
            <MKBox mt={-6} mb={1} sx={{ justifyContent: "center", display: "flex" }}>
              <img src={logo} srcSet={logo} alt="MasterCool Logo" width="80%" />
            </MKBox>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={0}
            >
              HVAC NETWORK SPECIALISTS
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={2}
            >
              WELCOME {username} <br /> {companyName}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Container sx={{ mt: 6 }}>
          <Information />
        </Container>
        <Features />
        <Container sx={{ mt: 6 }}>
          <BigCard />
        </Container>
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Thank you for your support!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  We will deliver the best service for your HVAC system
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6} style={{ textAlign: "center" }}>
        <MKTypography variant="button" fontWeight="regular">
          All rights reserved. Copyright &copy; {date} MasterCool
        </MKTypography>
      </MKBox>
    </>
  );
};

export default Home;
