import { Navigate, useOutlet } from "react-router-dom";

export const ProtectedLayout = ({ isAllowed }) => {
  const outlet = useOutlet();
  if (!isAllowed) {
    return <Navigate to="/login" replace />;
  }

  return <>{outlet}</>;
};
