import React, { useEffect, useState } from "react";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinnerOverlay from "components/LoadingSpinnerOverlay/LoadingSpinnerOverlay";
import MKTypography from "components/MKTypography";
import { BaseAPIURL } from "services/AuthService";
import PropTypes from "prop-types";
import { unitHistoryAsyncApi } from "../../services/ApiService";
import HistoryCard from "./HistoryCard/HistoryCard";
import Gallery from "./HistoryCard/Gallery";

function TabPanel(props) {
  const { history, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <HistoryCard
            color="info"
            image={history.image}
            reportName={history.activities.activity_code}
            date={history.date}
            datePass={history.date_pass}
            description={history.activity_description}
            content={history.content}
            bies={history.activity_bies}
            signedBy={history.signed_by}
            filePath={history.path}
            review={history.activity_comment}
            rating={history.activity_rating}
          />
          {history.photo_before && history.photo_before.length !== 0 ? (
            <Gallery title="Before" data={history.photo_before} url={BaseAPIURL} />
          ) : (
            ""
          )}
          {history.photo_after && history.photo_after.length !== 0 ? (
            <Gallery title="After" data={history.photo_after} url={BaseAPIURL} />
          ) : (
            ""
          )}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function UnitHistory() {
  const navigate = useNavigate();
  const { unitId } = useParams();
  const unitStructure = {
    activities: {},
    activity_code: "",
    activity_description: "",
    content: {},
    content_before: {},
    created_at: "",
    date: "",
    id: "",
    id_activity: "",
    id_customer_product: "",
    path: "",
    path_before: "",
    report_code: "",
    updated_at: "",
  };
  const historyStructure = {
    code: "",
    content: {},
    customer_product_histories: {},
    id: "",
  };
  const [unit, setUnit] = useState(unitStructure);
  const [histories, setHistories] = useState(historyStructure);
  const [isLoading, setIsLoading] = useState(true);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let subscribed = true;
    setIsLoading(true);
    unitHistoryAsyncApi(unitId)
      .then((res) => {
        setIsLoading(false);
        if (subscribed) {
          setUnit(res.data.data);
          setHistories(res.data.data.customer_product_history);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });

    return () => {
      subscribed = true;
    };
  }, []);

  const showHistoryCard = () => {
    return histories.length !== 0 ? (
      histories.map((history, index) => {
        return <TabPanel key={history.id} value={value} index={index} history={history} />;
      })
    ) : (
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ justifyContent: "center", display: "flex" }}
      >
        <MKTypography variant="h4">No Data Available</MKTypography>
      </Grid>
    );
  };

  return (
    <LoadingSpinnerOverlay isActive={isLoading}>
      <BaseLayout
        title="Unit History"
        breadcrumb={[{ label: "Unit", route: "/unit" }, { label: "History" }]}
      >
        <Box sx={{ fbgcolor: "background.paper" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            centered
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {Array.isArray(histories) &&
              (histories.length !== 0
                ? histories.map((history, index) => {
                    return (
                      <Tab label={history.activity_code} id={`horizontal-tab-${index}`} wrapped />
                    );
                  })
                : "")}
          </Tabs>
          {Array.isArray(histories) ? (
            showHistoryCard()
          ) : (
            <MKTypography>No Data Available</MKTypography>
          )}
        </Box>
      </BaseLayout>
    </LoadingSpinnerOverlay>
  );
}

export default UnitHistory;
