/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import { Chip, Divider, Grid, Stack } from "@mui/material";
import MKButton from "components/MKButton";
import { BaseAPIURL } from "services/AuthService";
import Gallery from "./Gallery";

function HistoryCard({
  color,
  image,
  reportName,
  date,
  datePass,
  description,
  content,
  bies,
  signedBy,
  filePath,
  review,
  rating,
}) {
  const ratings = {
    0.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    1: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    1.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    2: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    2.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    3: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    3.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    4: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_outline
      </Icon>,
    ],
    4.5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star_half
      </Icon>,
    ],
    5: [
      <Icon key={1} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={2} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={3} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={4} style={{ color: "#FFD700" }}>
        star
      </Icon>,
      <Icon key={5} style={{ color: "#FFD700" }}>
        star
      </Icon>,
    ],
  };

  const gridContent = (rows) => {
    return (
      <Grid
        container
        spacing={1}
        my={2}
        sx={{
          "--Grid-borderWidth": "2px",
          borderTop: "var(--Grid-borderWidth) solid",
          borderLeft: "var(--Grid-borderWidth) solid",
          borderColor: "divider",
          borderRadius: "4px",
          "& > div": {
            borderRight: "var(--Grid-borderWidth) solid",
            borderBottom: "var(--Grid-borderWidth) solid",
            borderColor: "divider",
          },
        }}
      >
        {rows.map((row) => {
          let gridLength = 0;
          let photoUrl = [];
          if (row.length === "half") {
            gridLength = 6;
          } else if (row.length === "full") {
            gridLength = 12;
          } else {
            gridLength = 4;
          }

          if (row.type === "upload") {
            // Convert the array to an array of objects with id and path
            photoUrl = row.value.map((path, index) => ({
              id: index + 1, // Assign a sequential id starting from 1
              path: `/${path}`, // Prepend a forward slash to the path
            }));
          }
          return (
            <Grid item xl={gridLength} md={gridLength} xs={12} px={1}>
              <Grid
                style={{
                  direction: "row",
                  diplay: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>
                  <Chip label={`${row.key}:`} variant="outlined" style={{ color: "white" }} />
                </Grid>
                <Grid item xs={12} style={{ padding: 5 }}>
                  <MKTypography
                    variant="body2"
                    fontWeight="regular"
                    color={color === "transparent" || color === "light" ? "text" : "white"}
                  >
                    {row.type === "upload" ? (
                      <Gallery title="" data={photoUrl} url={BaseAPIURL} />
                    ) : (
                      `${row.value} ${row.unit ?? ""}`
                    )}
                  </MKTypography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.download = `${reportName}.pdf`;
    link.href = `${BaseAPIURL}/${filePath}`;
    link.target = `_blank`;
    link.click();
  };

  return (
    <MKBox
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
      my={3}
    >
      {image && (
        <MKAvatar
          src={image}
          alt={reportName}
          variant="rounded"
          size="lg"
          shadow="md"
          sx={{ mt: -5, mb: 1 }}
        />
      )}
      <MKBox lineHeight={1}>
        <MKTypography
          display="block"
          variant="h4"
          fontWeight="bold"
          color={color === "transparent" || color === "light" ? "dark" : "white"}
          mb={0.5}
        >
          {reportName}
        </MKTypography>
        <Grid container>
          <Grid item xs>
            <MKTypography
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              lineHeight={1}
              color={color === "transparent" || color === "light" ? "text" : "white"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid container style={{ alignItems: "baseline" }}>
                <Grid item xs="auto">
                  <Icon>calendar_month</Icon>&nbsp;
                </Grid>
                <Grid item xs>
                  {date}
                </Grid>
              </Grid>
            </MKTypography>
          </Grid>
          <Grid item xs>
            <MKTypography
              variant={image ? "caption" : "button"}
              fontWeight="regular"
              lineHeight={1}
              color={color === "transparent" || color === "light" ? "text" : "white"}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid container style={{ alignItems: "baseline" }}>
                <Grid item xs="auto">
                  <Icon>schedule</Icon>&nbsp;
                </Grid>
                <Grid item xs>
                  {datePass}
                </Grid>
              </Grid>
            </MKTypography>
          </Grid>
        </Grid>
        <Grid>
          <MKTypography
            variant={image ? "caption" : "button"}
            fontWeight="regular"
            lineHeight={1}
            color={color === "transparent" || color === "light" ? "text" : "white"}
            my={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid container style={{ alignItems: "baseline" }}>
              <Grid item xs="auto">
                <Icon>home_repair_service</Icon>&nbsp;Service:&nbsp;
              </Grid>
              <Grid item xs>
                {description ?? "-"}
              </Grid>
            </Grid>
          </MKTypography>
        </Grid>
        <Grid>
          <MKTypography
            variant={image ? "caption" : "button"}
            fontWeight="regular"
            lineHeight={1}
            color={color === "transparent" || color === "light" ? "text" : "white"}
            my={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid container style={{ alignItems: "baseline" }}>
              <Grid item xs="auto">
                <Icon>person3</Icon>&nbsp;Done By:&nbsp;
              </Grid>
              <Grid item xs>
                {Array.isArray(bies) ? bies.join(", ") : "-"}
              </Grid>
            </Grid>
          </MKTypography>
        </Grid>
      </MKBox>
      {content ? gridContent(content) : ""}
      <Grid>
        <MKButton onClick={downloadFile}>Download Report Here</MKButton>
      </Grid>
      <Grid>
        <MKTypography
          variant="body2"
          color={color === "transparent" || color === "light" ? "text" : "white"}
          my={4}
        >
          {signedBy}
        </MKTypography>
        {/* <MKTypography
        variant="body2"
        color={color === "transparent" || color === "light" ? "text" : "white"}
        my={4}
      >
        &quot;{review}&quot;
      </MKTypography> */}
        <MKTypography
          variant="h4"
          color={color === "transparent" || color === "light" ? "text" : "white"}
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 0.375,
            "& .material-icons-round": {
              ml: -0.375,
            },
          }}
        >
          {ratings[rating]}
        </MKTypography>
      </Grid>
    </MKBox>
  );
}

// Setting default values for the props of HistoryCard
HistoryCard.defaultProps = {
  color: "transparent",
  image: "",
  content: [],
  bies: [],
  filePath: "",
};

// Typechecking props for the HistoryCard
HistoryCard.propTypes = {
  color: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string,
  reportName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  datePass: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.array,
  bies: PropTypes.array,
  signedBy: PropTypes.string.isRequired,
  filePath: PropTypes.string,
  review: PropTypes.string.isRequired,
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
};

export default HistoryCard;
